.table-editor .container-fluid {
    height: 100%;
}
.table-editor .container-fluid .fade-in {
    height: 100%;
}
.table-editor .container-fluid .fade-in .card {
    height: 100%;
}
.table-editor .container-fluid .fade-in .card .table-theme {
    height: calc(100% - 55px);
}
.align-right {
    text-align: right;
}
.table-diff-new {
    background: #2eb85c !important;
    color: white !important;
}
.table-diff-removed {
    background: #e55353 !important;
    color: white !important;
}
.table-diff-same {
    background: white !important;
    color: black !important;
}
.table-diff-modified {
    background: #f9b115 !important;
    color: white !important;
}
